import React from "react"
import { Link } from "gatsby"

import "../components/font-awesome"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Pager from "./pager"
import ReactMarkdown from "react-markdown"

import defaultImage from "../images/slider-01.jpg"

const continousTrainingList = ({ data, pageContext }) => {
  return (
    <Layout>
      <SEO title="Formación Continua - ICDA" />
      <section className="mb-3">
        <div className="container">
          <div className="mt-5">
            <div className="d-flex align-items-center mb-3">
              <div className="flex-grow-1">
                <h5 className="text-uppercase">
                  Formación continua{" "}
                  <span className="font-weight-bold text-main">ICDA</span>
                </h5>
              </div>
            </div>
          </div>
          {data.allStrapiFjsCourses.edges.length != 0 ?
            <Cards edges={data.allStrapiFjsCourses.edges} />
          : <p class="text-fjscourses">No se encontraron cursos</p> 
          }
          <div>
            <Pager pageContext={pageContext} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

function Cards({ edges }) {
  return (
    <div className="row">
      {edges.map(edge => {
        let { node: training } = edge
        let courseStart = new Date(training.dateStart)

        return (
          <div className="pb-4 col-lg-6 col-xs-12 order-1" key={training}>
            <div
              className="flex-grow-1 d-flex flex-column align-items-start list-academic-img h-100"
              style={{
                backgroundImage: `url(${
                  training.image
                    ? training.image.url
                    : defaultImage
                })`,
                minHeight: "inherit",
              }}
            >
              <div className="p-3 text-white d-flex flex-column background h-100">
                {/* Etieta de las Card - FJSCourses */}
                {new Date() > courseStart  ?
                  <div className={"bg-sec px-2 py-1 mx-3 my-3 fit-content"} style={{fontWeight: "bold"}}>
                    <div>{"COMENZADO"}</div>
                  </div>
                : null}

                <div className="flex-grow-1 d-flex flex-column justify-content-center text-white p-5">
                  <h3>{training.name}</h3>
                  <ReactMarkdown source={training.description} />
                  <Link
                    to={`https://fjs.ucc.edu.ar/${training.slug}`}
                    className="btn btn-outline-light fit-content mt-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ver más
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default continousTrainingList
export const continousTrainingListQuery = graphql`
query($skip: Int, $limit: Int) {
  allStrapiFjsCourses(
    filter: { academic_unit: { name: {eq: "ICDA"} } }
    skip: $skip
    limit: $limit
    sort: { fields: dateStart, order: DESC }
  ) {
    edges {
      node {
        name
        id
        slug
        image {
          url
        }
        dateStart(formatString: "DD MMM YYYY", locale: "ES-AR")
      }
    }
  }
}
`
